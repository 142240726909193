const feedbackParallaxModule = () => {
    const parallaxItems = document.querySelectorAll('.feedback__parallax-bg');

    const bottomFixPosition = -50;

    const firsAnimation = (event) => {
        parallaxItems.forEach((item) => {

            const itemDataAttr = item.getAttribute('data-parallax-row');

            switch (itemDataAttr) {

                case 'bright-firs': {

                    const x = event.clientX / window.innerWidth;
                    const y = event.clientY / window.innerHeight;     

                    item.style.transform = `translate(-${x * 130}px, -${y * 100}px)`;

                    break;
                };

                case 'dark-firs': {

                    const x = event.clientX / window.innerWidth;
                    const y = event.clientY / window.innerHeight;     


                    item.style.transform = `translate(-${x * 90}px, -${y * 50}px)`;

                    break;

                };

                default:
                    break;
            };

        });
    }

    
    window.innerWidth <= 1024 ? document.removeEventListener("mousemove", firsAnimation) 
        : document.addEventListener("mousemove", firsAnimation);
};

feedbackParallaxModule();