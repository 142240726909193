const fixHeader = () => {

    const header = document.querySelector('.header');

    window.addEventListener('scroll', (e) => {

        const scrollY = window.scrollY;

        scrollY > 0 ? header.classList.add('fixed') : header.classList.remove('fixed');

    });

};

fixHeader();
