const burgerMenuModule = () => {

    const burgerMenu = document.querySelector('.burger-menu');
    const burgerMenuOverlay = document.querySelector('.burger-menu__overlay');
    const body = document.querySelector('body');

    const openBurgerMenu = (paddingOffset) => {
        burgerMenu.classList.add('visible');
        body.style.overflow = 'hidden';
        body.style.paddingRight = paddingOffset;
    };

    const closeBurgerMenu = () => {
        burgerMenu.classList.remove('visible');
        setTimeout(() => {
            body.style.overflow = 'visible';
            body.style.paddingRigth = 0;
        }, 400)
    };

    document.addEventListener('click', (e) => {

        const target = e.target;
        const paddingOffset = `${window.innerWidth - document.body.offsetWidth}px`;

        if (target.closest('.header__burger-menu')) { 
            openBurgerMenu(paddingOffset);
        };

        if (target.closest('.burger-menu__close-button')) {
            closeBurgerMenu();
        };

        if (target === burgerMenuOverlay) {
            closeBurgerMenu();
        };

    });

}

burgerMenuModule();