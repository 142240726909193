const contactsMap = () => {
    try {
        const triggers = document.querySelectorAll('.contacts__tabs-trigger');
        const contentItems = document.querySelectorAll('.contacts__tabs-content-item');

        let firstPoint = [52.03774607207121,113.50372599999999];
        let secondPoint = [51.694353052188156,39.342517499999914];

        function init() {
            let map = new ymaps.Map('contacts-map', {
                center: firstPoint,
                zoom: 17
            });

            let firstPointPlacemark = new ymaps.Placemark(firstPoint, {}, {
                iconLayout: 'default#image',
                iconImageHref: '../../img/icons/placemark.svg',
                iconImageSize: [40, 40],
                iconImageOffset: [-19, -44]
            });

            let secondPointPlacemark = new ymaps.Placemark(secondPoint, {}, {
                iconLayout: 'default#image',
                iconImageHref: '../../img/icons/placemark.svg',
                iconImageSize: [40, 40],
                iconImageOffset: [-19, -44]
            });

            triggers.forEach(trigger => {

                trigger.addEventListener('click', (e) => {
        
                    const currentTarget = e.currentTarget;
                    const triggerDataAttr = currentTarget.getAttribute('data-tab-button');

                    if (triggerDataAttr === 'tab-1') {
                        map.setCenter(firstPoint);
                    } else if (triggerDataAttr === 'tab-2') {
                        map.setCenter(secondPoint);
                    };
        
                    triggers.forEach(trigger => {
                        trigger.classList.remove('active');
                    });
            
                    contentItems.forEach(contentItem => {
            
                        const contentDataAttr = contentItem.getAttribute('data-tab-content');
            
                        contentItem.classList.remove('active');
            
                        if (triggerDataAttr === contentDataAttr) {
                            currentTarget.classList.add('active');
                            contentItem.classList.add('active');
                        };
            
                    });
        
                });
            });
    
            map.controls.remove('geolocationControl'); // удаляем геолокацию
            map.controls.remove('searchControl'); // удаляем поиск
            map.controls.remove('trafficControl'); // удаляем контроль трафика
            map.controls.remove('typeSelector'); // удаляем тип
            map.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
            map.controls.remove('zoomControl'); // удаляем контрол зуммирования
            map.controls.remove('rulerControl'); // удаляем контрол правил
            map.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)

            map.geoObjects.add(firstPointPlacemark);
            map.geoObjects.add(secondPointPlacemark);
        }
    
        ymaps.ready(init);
    } catch (error) {
        
    }
}

contactsMap();