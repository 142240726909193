import Swiper from 'swiper/bundle';


const mainSwiperModule = () => {

    const swiperSlides = document.querySelectorAll('.main__slider-slide');
    const swiperPagination = [];
    
    for (let i = 1; i <= swiperSlides.length; i++) {

        const swiperPaginationItem = `0${i}`; 
        swiperPagination.push(swiperPaginationItem);

    };
    
    const swiper = new Swiper('.main__slider', {
    
        grabCursor: true,
        rewind: true,
    
        pagination: {
            el: '.main__slider-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return `<span class="${className}">${swiperPagination[index]}</span>`
            }
        },

    });
};

mainSwiperModule();


