import Choices from 'choices.js';
// import 'simplebar';
// import 'simplebar/dist/simplebar.css';

const selectInit = () => {

    const selects = document.querySelectorAll('.ui-select');
    

    selects.forEach(select => {

        new Choices(select, {
            searchEnabled: false,
            itemSelectText: ''
        });

        // const parentNode = select.parentNode.parentNode;
        // const selectList = parentNode.querySelector('.choices__list--dropdown');

        // selectList.setAttribute('data-simplebar', true);

    });

};

selectInit();

