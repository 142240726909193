import Swiper from 'swiper/bundle';

const newProjectsSlider = () => {

    const newProjectsSlider = new Swiper('.new-projects__slider', {

        

        navigation: {
          nextEl: '.new-projects__slide-next-button',
          prevEl: '.new-projects__slide-prev-button',
        },

        breakpoints: {
            325: {
                spaceBetween: 10,
                slidesPerView: 1,
            },

            475: {
                spaceBetween: 10,
                slidesPerView: 2
            },

            768: {
                spaceBetween: 10,
                slidesPerView: 3
            },

            1025: {
                spaceBetween: 20,
                slidesPerView: 3.3,
            },

            1200:{
                spaceBetween: 30,
                slidesPerView: 3.3,
            },

            1440: {
                spaceBetween: 50,
                slidesPerView: 3.3
            }
        }

    });
};

newProjectsSlider();