const modalModule = (obj) => {

    try {
        const modal = document.querySelector(obj.modal);
        const overlay = document.querySelector(`.${obj.overlay}`);
        const header = document.querySelector('header');
        const body = document.querySelector('body');

        const openModal = (e, paddingOffset) => {
            e.preventDefault();

            header.style.transition = 'none';

            modal.classList.add('visible');
            body.style.overflow = 'hidden';
            body.style.paddingRight = paddingOffset;
            overlay.style.paddingRight = paddingOffset;
            header.style.paddingRight = paddingOffset;
        };

        const closeModal = () => {
            header.style.paddingRight = 0;
            header.style.transition = 'all .3s ease';
            setTimeout(() => {
                modal.classList.remove('visible');
                body.style.overflow = 'visible';
                body.style.paddingRight = 0;
                overlay.style.paddingRight = 0;
            }, 200);
        };
        
        document.addEventListener('click', (e) => {

            const target = e.target;
            const paddingOffset = `${window.innerWidth - document.body.offsetWidth}px`;

            target.closest(obj.trigger) ? openModal(e, paddingOffset) : '';
            target.classList.contains(obj.overlay) ? closeModal() : '';
            target.closest('.modal__close-button') ? closeModal() : '';

        });

    } catch (error) {

    }

};


modalModule({
    modal: '.request-modal ',
    overlay: 'request-modal__overlay',
    inner: '.request-modal__inner',
    trigger: '.-js-request-modal'
});

modalModule({
    modal: '.order-modal ',
    overlay: 'order-modal__overlay',
    inner: '.order-modal__inner',
    trigger: '.-js-order-modal'
});