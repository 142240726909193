const caseAsideMenu = () => {

    const sections = document.querySelectorAll('.case-content__section');
    const links = document.querySelectorAll('.case-content__nav-link');

    if (links) {

        const observer = new IntersectionObserver(entires => {
        
            entires.forEach(entry => {
    
                if (entry.isIntersecting) {
    
                    links.forEach(link => {
    
                        const linkId = link.getAttribute('href').replace('#', '');
    
                        linkId === entry.target.id ? link.classList.add('active') : link.classList.remove('active');
    
                    });
                };
            });
        }, {
            threshold: 0
        });
    
        sections.forEach(section => { observer.observe(section) });

    };
};

caseAsideMenu();